import React from "react";
import {Typography} from "@mui/material";

export class About extends React.Component {
    render() {
        return (
            <>
                <Typography variant="h3">
                    Movie Catalogue
                </Typography>

                <p>This is sample application, for my ReactJS learning purposes.</p>
            </>
        );
    }
}
